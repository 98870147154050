import React, { useState } from 'react';
import './App.css';
import ExpandableImg from './Expandableimg';

import Linkedin from './img/linkedin.png'
import Mail from './img/mail.png'
import Owen from './img/owen.png';
import Anemone from './img/Anemone.gif';
import AnemoneLayer from './img/AnemoneLayerPage.png';
import AnemoneLayered from './img/AnemoneLayeredPage.png';
import GreenRoof from './img/greenroof.png';
import GreenRoof2 from './img/GreenRoofDashboard.png';
import GreenRoof3 from './img/GreenRoofMapPage.png';
import rcubed from './img/rcubed.png';
import rcubed2 from './img/RCubedMapPage.png';
import rcubed3 from './img/RCubedLocationFinder.png';
import oceansfive from './img/Oceansfive.png';
import oceansfive2 from './img/OceansBeachPage.png';
import oceansfive3 from './img/OceansBeaches.png';


const projectsJSON = [{
  "name": 'Anemone Capstone Project',
  'date': "December 2022 - June 2023",
  'content': "Project Anemone is a data visualization platform built for the startup starfish space. The project was built in React.js, HTML and CSS. This application displays satellite data to better help display and analyze data from tests.",
  'content2': 'For this project I worked in a team of five as the front end engineer. I wrote most of the HTML, CSS, and Javascript for the project while also working to design the layer page seen below. This page was used to easily compare two different graphs side by side.',
  'content3': 'This app was first built as a wireframe in Figma. Below you can see what the graphs looked like when they were placed on top of one another. (click image to expand)', 'img': Anemone,
  'img2': AnemoneLayer,
  'img3': AnemoneLayered,

}, {
  "name": 'Oceansfive',
  'date': 'April 2023 - June 2023',
  'content': 'Oceansfive is a web application built in React.js, HTML and CSS. The application helps users find snorkeling locations in the Hawaiian island of Oahu',
  'content2': 'For this project I worked in a group of five as the front end engineer. I wrote HTML, CSS and Javascript for this project while also working on the Figma to help display the logical information. I helped design the location page and the location cards seen below. The icons at the bottom of each card display what amenities are at each location.',
  'content3': 'This app was built as a wireframe in Figma, then created using HTML, CSS, Javascript, and React. Below you can see what an expanded card looked like.',
  'img': oceansfive,
  'img2': oceansfive2,
  'img3': oceansfive3,
}, {
  "name": 'RCubed',
  'date': 'January 2022 - March 2022',
  'content': 'RCubed is a mobile app built in Figma that helps users find ways to recycle, reuse and repair electronics.',
  'content2': 'This project was built in a team of 5 and I worked primarily on creating the map page for this app. Below you can see a map of the University of Washington campus with locations on where to recycle electronics.',
  'content3': 'This project was built as only a wireframe in Figma. Below you can see what happens when you hover a location.',
  'img': rcubed,
  'img2': rcubed2,
  'img3': rcubed3,
}, {
  "name": 'GreenRoof',
  'date': 'July 2020 - August 2020',
  'content': 'GreenRoof is a mobile app built in Figma which helps users find solar panel installer companies.',
  'content2': 'For this project I worked in a team of 4 as the project manager and as the designer of the "potential solar panel owner flow". Below you can see the dashboard of the app',
  'content3': 'This project was built as only a wireframe in Figma. Below you can see what happens when you click "Find Solar Providers" in the above image.',
  'img': GreenRoof,
  'img2': GreenRoof2,
  'img3': GreenRoof3,
}]


function ExpandableDiv({ title, content, content2, img, img2 }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="expandable-div">
      <div className="expandable-header" onClick={toggleOpen}>
        <a className='button'>{isOpen ? 'Close' : 'View'}</a>
      </div>
      {isOpen && (
        <div className="expandable-content">
          <p>{content}</p>
          <ExpandableImg
            src={img}
          />
          <p>{content2}</p>
          <ExpandableImg
            src={img2}
          />
        </div>
      )}
    </div>
  );
}






function App() {
  let projectMap = 'hello'
  const [projectContent, setProjectContent] = useState(projectMap)

  let techStack = [{ name: 'HTML', link: 'https://www.w3schools.com/html/default.asp', img: '' }, { name: 'CSS', link: 'https://www.w3schools.com/css/default.asp', img: '' }, { name: 'JavaScript', link: 'https://www.w3schools.com/js/default.asp', img: '' }, { name: 'React', link: 'https://reactjs.org/', img: '' }, { name: 'Node.js', link: 'https://nodejs.org/en/', img: '' }, { name: 'Python', link: 'https://www.python.org/', img: '' }];
  let applications = [{ name: 'VSCode', link: 'https://code.visualstudio.com/', img: '' }, { name: 'GitHub', link: '', img: '' }, { name: 'Figma', link: '', img: '' }, { name: 'Slack', link: '', img: '' }, { name: 'Zoom', link: '', img: '' }, { name: 'Jira', link: '', img: '' }];
  projectMap = projectsJSON.map((project) => {
    return (
      <a href={project.link} target='_blank' rel='noreferrer'>
        <div className='project' >
          <h2>{project.name}</h2>
          <h4>{project.date}</h4>
          <img src={project.img} alt={project.name} className='project-img' />
          <p>{project.content}</p>
          <ExpandableDiv
            content={project.content2}
            content2={project.content3}
            img={project.img2}
            img2={project.img3}
          />
        </div>
      </a>
    )
  });
  let techStackMap = techStack.map((tech) => {
    return (
      <a className='button spacing' href={tech.link} target='_blank' rel='noreferrer'>{tech.name}</a>
    )
  });
  let applicationMap = applications.map((application) => {
    return (
      <a className='button spacing' href={application.link} target='_blank' rel='noreferrer'>{application.name}</a>
    )
  });
  return (
    <div>
      <div className="App">
        <header>
          <h1 className='title'>Owen's Portofolio</h1>
        </header>
        <body className='row'>
          <div className="column fixed">

            <img src={Owen} alt="Owen" className='headshot' />
            <div className='aboutMe' >
              <h2>About Me</h2>
              <p>Hi my name is Owen and I am a graduate from the University of Washington with a B.S. in Informatics. I am actively looking for a relevant job in the field!</p>
            </div>
            <div className='contact'>
              <h2>Contact Me</h2>
              <a href="mailto:olschaff@verizon.net"><img src={Mail} /></a>
              <a href="https://www.linkedin.com/in/owen-schaff-39b8a3219/" target="_blank"> <img src={Linkedin} /></a>
            </div>
            <div className='tech-stack'>
              <h2>Tech Stack</h2>
              {techStackMap}
            </div>
            <div className='applications'>
              <h2>Applications</h2>
              {applicationMap}
            </div>
          </div>
          <div className='column scroll'>

            <div className='projects'>
              <h2 className='bolded'>My Projects</h2>
              {projectMap}
            </div>

          </div>
        </body>
      </div>
    </div>
  );
}


export default App;