import React, { useState } from 'react';

function ExpandableImg({ src }) {
  const [isImageClicked, setIsImageClicked] = useState(false);

  const handleClick = () => {
    setIsImageClicked(true);
  };

  const handleClose = () => {
    setIsImageClicked(false);
  };

  return (
    <div>
      {isImageClicked ? (
        <div className="modal" onClick={handleClose}>
          <div className="modal-content">
            <img src={src} alt="Large Pop-out Image" className='img-expanded'/>
          </div>
        </div>
      ) : (
        <img
          src={src}
          alt="Thumbnail Image"
          onClick={handleClick}
          className='img-expand'
        />
      )}
    </div>
  );
}

export default ExpandableImg;